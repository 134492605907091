<script>
import {VueMaskDirective as mask} from 'v-mask'
import PrintA4 from "@/components/print/defaults/PrintA4"
import {notaArrematacao, registrarNotaArrematacao, enviarNotaArrematacaoParaArrematante} from '@/domain/leiloes/services/financeiro'
import {UProgress} from 'uloc-vue'
import lodingProgress from '@/reuse/loading/loadingMixin'

export default {
  components: {PrintA4, UProgress},
  mixins: [lodingProgress],
  nome: 'NotaLotePrint',
  props: [
    'id',
    'lotes',
    'tipo',
    'salvar',
    'enviarArrematante'
  ],
  directives: {mask},
  data() {
    return {
      isLoading: false,
      template: null,
      registrandoNota: false,
      cache: {
        enableProgress: true,
        progressDesc: 'Registrando nota',
        success: null
      }
    }
  },
  computed: {},
  mounted() {
    // document.body.append(this.$refs.floatProgress)
  },
  activated() {
  },
  created() {
    this.isLoading = true
    notaArrematacao(this.lotes, this.tipo, this.salvar, this.enviarArrematante)
        .then(({data}) => {
          this.template = data.template
          this.isLoading = false
          if (this.salvar) {
            this.registrandoNota = true
            this.startLoading()
            registrarNotaArrematacao(data.nota.id, {
              salvar: this.salvar,
              enviarArrematante: this.enviarArrematante
            }).then(() => {
              if (this.enviarArrematante) {
                this.cache.progressDesc = 'Enviando nota para o cliente'
                this.startLoading()
                enviarNotaArrematacaoParaArrematante(data.nota.id, {
                  salvar: this.salvar,
                  enviarArrematante: this.enviarArrematante
                }).then(() => {
                  this.stopLoading()
                  this.cache.success = true
                  setTimeout(() => {
                    this.registrandoNota = false
                  }, 3000)
                }).catch(error => {
                  this.alertApiError(error)
                  this.stopLoading()
                  this.cache.success = false
                })
              } else {
                this.stopLoading()
                this.cache.success = true
                setTimeout(() => {
                  this.registrandoNota = false
                }, 3000)
              }
            }).catch(error => {
              this.alertApiError(error)
              this.stopLoading()
              this.cache.success = false
            })
          }
        })
        .catch(error => {
          this.alertApiError(error)
          this.isLoading = false
        })
  },
  deactivated() {
  },
  beforeDestroy() {
    // document.body.removeChild(this.$refs.floatProgress)
  },
  watch: {},
  methods: {}
}
</script>

<template>
  <print-a4 :is-loading="isLoading">
    <div v-if="template" v-html="template.template"></div>
    <div v-if="registrandoNota" ref="floatProgress" class="app-float-progress hidden-print" style="min-width: 250px">
      <div v-if="!cache.success">
        {{cache.progressDesc}}
        <div>
          <u-progress v-if="cache.enableProgress" class="m-t" :percentage="loadingProgress" :animate="true"
                      :color="loadingProgress < 99 ? 'positive' : 'positive'" height="10px"/>
        </div>
      </div>
      <div v-else>
        <div class="flex items-center">
          <div class="success-checkmark" style="zoom: 0.3">
            <div class="check-icon">
              <span class="icon-line line-tip"></span>
              <span class="icon-line line-long"></span>
              <div class="icon-circle"></div>
              <div class="icon-fix"></div>
            </div>
          </div>
          <div class="m-l m-t-n-xs">Nota registrada com sucesso!</div>
        </div>
      </div>
    </div>
  </print-a4>
</template>
